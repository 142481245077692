import '../css/style.css'
import '../css/fonts.css'
import '../css/style-media.css'


let memberMoreButtonArray = document.querySelectorAll('.memberMoreButton')
let memberBlockArray = document.querySelectorAll('.memberBlock')
let memberPhotoArray = document.querySelectorAll('.memberPhoto')

for (let i = 0; i < memberMoreButtonArray.length; i++) {
    memberMoreButtonArray[i].addEventListener('click', ()=> {
        memberBlockArray.forEach(element => {
            element.classList.remove('active');
        });
        memberBlockArray[i].classList.toggle('active');
    }) 
}
for (let i = 0; i < memberPhotoArray.length; i++) {
    memberPhotoArray[i].addEventListener('click', ()=> {
        memberBlockArray.forEach(element => {
            element.classList.remove('active');
        });
        memberBlockArray[i].classList.toggle('active');
    }) 
}


let burgerMenu = document.querySelector('.burger')
let headerMenu = document.querySelector('.headerMenu')
if( burgerMenu != null ) {
    burgerMenu.addEventListener('click', () => {
        headerMenu.classList.toggle('active');
        burgerMenu.classList.toggle('active');
    })
}



let documentHeight = document.body.clientHeight
let myY = 0
let el1 = document.querySelector('.contentContainer .tittleContainer .tittleFill')
let el2 = document.querySelector('.contentContainer .tittleContainer .tittleContur')
let el3 = document.querySelector('.contentContainer .tittleContainer .tittleDescription')
let el4 = document.querySelector('.offerTittle')
let el5 = document.querySelector('.offerBox')
let el6 = document.querySelector('.offerDescriptionBlock .offerTittleContur')
let el7 = document.querySelector('.partnersPlanet')
let p1 = document.querySelector('.photoBlock:nth-child(1)')
let p2 = document.querySelector('.photoBlock:nth-child(2)')
let p3 = document.querySelector('.photoBlock:nth-child(3)')
let p4 = document.querySelector('.photoBlock:nth-child(4)')
let p5 = document.querySelector('.photoBlock:nth-child(5)')
let p6 = document.querySelector('.photoBlock:nth-child(6)')
let el8 = document.querySelector('.planetImage')
let el9 = document.querySelector('.projectsContainer .tittleFill')
let el10 = document.querySelector('.projectsContainer .tittleContur')

if (document.body.clientWidth > 1200) {
    window.addEventListener('scroll', e => {
        let currentPoint = window.scrollY / documentHeight
        // console.dir(document.body.clientHeight)
        myY = window.scrollY
        
        el1.style.cssText = `transform: translateY(${myY * 0.06}px); transition: 0.15s ease;`
        el2.style.cssText = `transform: translateY(${myY * 0.03}px); transition: 0.15s ease;`
        el3.style.cssText = `transform: translateY(${myY * 0.01}px); transition: 0.15s ease;`
    
        el4.style.cssText = `transform: translateY(${myY * 0.01}px); transition: 0.65s ease;`
        el5.style.cssText = `transform: rotate(${currentPoint * 30}deg); transition: 0.15s ease;`
        el6.style.cssText = `transform: translateY(${currentPoint * -100}px); transition: 0.75s ease;`
        if (el7 != null) {
            el7.style.cssText = `transform: translateY(${currentPoint * 100 - 25}px); transition: 0.45s ease;`
        }
        if (p1 != null) {
            p1.style.cssText = `transform: translateY(${currentPoint * 200 - 240}px); transition: 0.45s ease;`
        }
        if (p2 != null) {
            p2.style.cssText = `transform: translateY(${currentPoint * 300 - 240}px); transition: 0.45s ease;`
        }
        if (p3 != null) {
            p3.style.cssText = `transform: translateY(${currentPoint * 400 - 240}px); transition: 0.45s ease;`
        }
        if (p4 != null) {
            p4.style.cssText = `transform: translateY(${currentPoint * 200 - 240}px); transition: 0.45s ease;`
        }
        if (p5 != null) {
            p5.style.cssText = `transform: translateY(${currentPoint * 100 - 20}px); transition: 0.45s ease;`
        }
        if (p6 != null) {
            p6.style.cssText = `transform: translateY(${currentPoint * 100 - 120}px); transition: 0.45s ease;`
        }
    
        if (el8 != null) {
            el8.style.cssText = `transform: translateY(${currentPoint * 250 - 80}px); transition: 0.65s ease;`
        }
        if (el9 != null) {
            el9.style.cssText = `transform: translateY(${currentPoint * 150 - 80}px); transition: 0.65s ease;`
        }
        if (el10 != null) {
            el10.style.cssText = `transform: translateY(${currentPoint * 250 - 60}px); transition: 0.65s ease;`
        }
    })
}


let chooseMailToBlock = document.querySelector('.chooseMailToBlock')
let mailToArray = document.querySelectorAll('.chooseMailTo')
let inputMailTo = document.querySelector('.footerInput.mailTo')
let chooseMailToArrow = document.querySelector('.chooseMailToArrow')

for (let i = 0; i < mailToArray.length; i++) {
    mailToArray[i].addEventListener('click', () => {
        mailToArray.forEach(element => {
            element.classList.remove('active')
        });
        mailToArray[i].classList.add('active')
        inputMailTo.value = mailToArray[i].attributes.mail.value
        if (chooseMailToBlock.classList.contains('active')) {
            chooseMailToBlock.classList.remove('active')
        }
    })    
}
inputMailTo.addEventListener('click', () => {
    if (document.body.clientWidth < 1280) {
        setTimeout(() => {
                chooseMailToBlock.classList.toggle('active')
                chooseMailToArrow.classList.toggle('active')
        }, 10);
    }
})

document.addEventListener('click', (e) => {
    chooseMailToBlock.classList.remove('active')
    chooseMailToArrow.classList.remove('active')
})